import React from 'react'
import xss from 'xss'

import styled from 'styled-components'
import AccordionOpenIcon from '../images/accordion_open_icon.svg'
import AccordionCloseIcon from '../images/accordion_close_icon.svg'
const AccordionWrapper = styled.div`
  border-radius: 8px;
  background: #f5efe7;
  padding: 16px;
  display: flex;
  flex-direction: column;
`

const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 16px;
  font-size: 20px;
  line-height: 28px;
  color: #000;
  &:hover {
    cursor: pointer;
  }
`

const AccordionContent = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #1d192b;
  padding-left: 64px;

  & ul {
    margin: 16px 0;
  }

  & > ul {
    list-style-type: decimal;
  }

  & ul li {
    margin-top: 0px;
    font-size: 16px;
    line-height: 24px;
  }

  & ol > li {
    margin-bottom: 16px;
  }
`

const AccordionTitle = styled.p`
  font-size: 20px;
  line-height: 28px;
  margin-top: 0;
`

const AccordionOrderedList = styled.ol`
  padding-left: 64px;
  margin-top: 0;
  & li {
    margin-top: 16px;
  }
`

const AccordionContentTitle = styled.p`
  color: #352f26;
  font-size: 16px;
  line-height: 24px;
  font-weight: 800;
  margin-bottom: 8px;
`
const AccordionContentListItem = styled.li`
  margin-top: 0;
  &::marker {
    font-size: 16px;
    line-height: 24px;
  }
`
const AccordionContentListItemHTML = styled(AccordionContent)`
  padding-left: 0;
  margin-top: 0;
`

const Accordion = ({ title, content, layeredContent }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <AccordionWrapper>
      <AccordionHeader onClick={() => setOpen(!open)}>
        <img src={open ? AccordionCloseIcon : AccordionOpenIcon} />
        <AccordionTitle
          dangerouslySetInnerHTML={{
            __html: xss(title),
          }}
        />
      </AccordionHeader>
      {open && content && (
        <AccordionContent
          dangerouslySetInnerHTML={{
            __html: xss(content),
          }}
        />
      )}
      {open && layeredContent && (
        <AccordionOrderedList>
          {layeredContent.map((elem, index) => (
            <AccordionContentListItem key={`${elem[0]}-${index}`}>
              {elem.title && (
                <AccordionContentTitle>{elem.title}</AccordionContentTitle>
              )}
              <AccordionContentListItemHTML
                dangerouslySetInnerHTML={{
                  __html: xss(elem.content),
                }}
              />
            </AccordionContentListItem>
          ))}
        </AccordionOrderedList>
      )}
    </AccordionWrapper>
  )
}

export default Accordion
