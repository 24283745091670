import * as React from 'react'
import { LevelsText } from './styles'
const LevelItem = ({ index, title, onClickHandler, active }) => {
  return (
    <LevelsText
      onClick={onClickHandler(index)}
      key={'button-' + index}
      active={active}
    >
      {title}
    </LevelsText>
  )
}

export default LevelItem
